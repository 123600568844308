import '../node_modules/@splidejs/splide/dist/js/splide';
import PhotoSwipe from '../node_modules/photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from '../node_modules/photoswipe/dist/photoswipe-ui-default';
import nextFriday from 'date-fns/nextFriday'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

document.addEventListener('DOMContentLoaded', function () {
  let pswpElement = document.querySelectorAll('.pswp')[0];
  let photoTargets = document.querySelectorAll('.photo')
  let items = [];
  photoTargets.forEach((photo) => {
    items.push({
      el: photo,
      src: photo.href,
      w: photo.dataset['width'],
      h: photo.dataset['height'],
    });
  });

  photoTargets.forEach((photo) => {
    photo.addEventListener('click', (event) => {
      event.preventDefault();
      let index = parseInt(event.currentTarget.dataset['index']);
      let gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, {
        index,
        getThumbBoundsFn: function (index) {
          let thumbnail = items[index].el, // find thumbnail
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect();

          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        },
      });
      gallery.init();
    })
  })

  new Splide('.splide', {
    perPage: 3,
    type: 'loop',
    autoplay: true,
    gap: '1rem',
    padding: {
      right: '5rem',
      left: '5rem',
    },
    breakpoints: {
      '600': {
        perPage: 1,
        gap: '.5rem',
        padding: {
          right: '2rem',
          left: '2rem',
        },
      },
      '768': {
        perPage: 2,
        gap: '.5rem',
        padding: {
          right: '2rem',
          left: '2rem',
        },
      },
    }
  }).mount();

});

let friday = nextFriday(new Date());
let sunday = addDays(friday, 2);

let iframe = document.createElement('iframe');
iframe.width = '100%';
iframe.classList.add('iframe-class');
iframe.frameBorder = 0;
iframe.id="cloudbeds";
iframe.src=`https://hotels.cloudbeds.com/reservation/q1e0Tz?isWidget=1&widget=1#checkin=${format(friday, "yyyy-MM-dd")}&checkout=${format(sunday, "yyyy-MM-dd")}`;
document.getElementById('cloudbeds-iframe').append(iframe);
